import React from 'react';
import { CARS_CREATE, CARS_UPDATE, GET_ALL_USERS, GET_CAR_BY_ID, INITIAL_CAR_VALUE, INITIAL_CAR_VALUE_ERROR } from '../../common/constants';
import { CheckCarRequiredFields } from '../../common/utils';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import CarForm from './CarForm';


const CarUpdate = ({car, setToast, refetch}) => {
    const [open, setOpen] = React.useState(!!car);
    const axiosPrivate = useAxiosPrivate();
    const [errors, serErrors] = React.useState(INITIAL_CAR_VALUE_ERROR);
    const [carsMapper, setCarsMapper] = React.useState(INITIAL_CAR_VALUE);
    const [users, setUsers] = React.useState([]);


    const builderCarMapper = (car) => {
        const handleTmpUserMapper = {
            id: car.id,
            type: car.type,
            license_plate: car.license_plate,
            brand: car.brand,
            model: car.model,
            initial_kilometers: car.initial_kilometers,
            company: car.company,
            userId: car.userId,
          };
          setCarsMapper(handleTmpUserMapper);


    }

    const getUsers = React.useCallback( async () => {
        const response = await axiosPrivate.get(GET_ALL_USERS);
        if(response.status === 201) {
          setUsers(response.data.data)
        }
    });

    const getCar = async () => {
        const response = await axiosPrivate.get(GET_CAR_BY_ID.replace(':id', car.id));
        if(response.data) {
            builderCarMapper(response.data);
            setOpen(true)
        } else {
            setOpen(false)
        }
    }


    const updateCar = async () => {
        const checkErrors = CheckCarRequiredFields(carsMapper);
        if(checkErrors.errorCounter === 0){
            await axiosPrivate.put(
                CARS_UPDATE,
                JSON.stringify({ carsMapper }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            ).then(() => {
                refetch();
                setCarsMapper(INITIAL_CAR_VALUE)
                setToast({
                    open: true,
                    message: "Vehículo modificado con éxito",
                    severity: "success",
                })   
            })
            .catch(() => {
                setToast({
                    open: true,
                    message: "Error modificado el Vehículo, intentelo de nuevo.",
                    severity: "error",
                });        
            });
            setOpen(false);
            serErrors(INITIAL_CAR_VALUE_ERROR); 
            
        }else{
            console.log('errors', errors);
            setToast({
                open: true,
                message: "Revisa los datos del formulario.",
                severity: "error",
            }); 
            serErrors(checkErrors.userErrors);
        }
    }

    React.useEffect(() => {
        if(car.id && !open){
            getCar();
            getUsers();

        }
    },[car]);

    return (
        <Panel
        title={"Editar Vehículo"}
        open={open}
        bodyStyle={{ overflow: "hidden" }}
        bodyText={<CarForm carData={carsMapper} users={users} setCarData={setCarsMapper} errors={errors}/>}
        buttonAccept={"aceptar"}
        acceptAction={() => updateCar()}
        requestClose={() => {serErrors(INITIAL_CAR_VALUE_ERROR); setCarsMapper(INITIAL_CAR_VALUE); setOpen(false); } }
      />
    )
};

export default CarUpdate;