import React from "react";
import { Box } from "@mui/system";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { tokens } from "../../theme/theme.js";
import { useTheme } from "@emotion/react";
import { Pagination, PaginationItem } from "@mui/material";
import { Dropdown, DropdownMenuItem } from "./Dropdown.js";

const EnhancedTable = ({
  data,
  header,
  options,
  setOptions,
  totalRow,
  onRowClick = false,
  laoding = false,

}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = React.useState(true);
  const [takeValue, setTakeValue] = React.useState(options.takeValue);
  const [skipValue, setSkipValue] = React.useState(options.skipValue);
  const [rowsPerPage, setRowsPerPage] = React.useState(options.rowsPerPage);
  const [numberPage,setNumberPage] = React.useState(1)


  React.useEffect(() => {
    if (data && data.length > 0) {
      setLoading(false)
    }
  }, [data]);

  React.useEffect(() => {
    setOptions({ ...options, takeValue, skipValue, rowsPerPage })
  }, [takeValue, skipValue, rowsPerPage]);

  const changePage = value => {
    setNumberPage(value)
    setSkipValue((value - 1) * rowsPerPage)
    setTakeValue(rowsPerPage)
    setLoading(true)
  }
  const changeRowsPerPage = value => {
    setNumberPage(1)
    setRowsPerPage(value)
  }

  React.useEffect(() => {
    changePage(numberPage)
  },[rowsPerPage])

  const CustomPagination = () => {

    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <>
        <div style={{ marginRight: '2.5rem'}}>
          <Dropdown
            trigger={<p style={{ fontSize: '1.05rem', cursor: 'pointer' }}>Filas por página: <span style={{ borderBottom: '1px solid' }}>{rowsPerPage}</span></p>}
            menu={[
              <DropdownMenuItem onClick={() => changeRowsPerPage(10)}>10</DropdownMenuItem>,
              <DropdownMenuItem onClick={() => changeRowsPerPage(30)}>30</DropdownMenuItem>,
              <DropdownMenuItem onClick={() => changeRowsPerPage(50)}>50</DropdownMenuItem>,
            ]}
          />
        </div>
        <Pagination
          color="primary"
          size="large"
          variant="outlined"
          shape="rounded"
          fontSize="1.25rem"
          page={page + 1}
          count={pageCount}
          siblingCount={0}
          boundaryCount={2}
          renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
          onChange={(event, value) => {
            changePage(value)
            apiRef.current.setPage(value - 1);
          }}
        />
      </>
    );
  };

  return (
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
          fontSize: "1.05rem",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
          fontSize: "1rem",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
        "& 	.MuiDataGrid-footerContainer": {
          justifyContent: "center",
        },
      }}
    >
      {data && (
        <>
          <DataGrid            
            disableColumnFilter
            rows={data}
            columns={header}
            rowCount={totalRow}
            components={{ Toolbar: GridToolbar, Pagination: CustomPagination }}
            pageSize={rowsPerPage}
            onRowClick={ev => onRowClick(ev.row)}
            loading={loading}
            disableSelectionOnClick
            paginationMode={"server"}
            disableExport={true}
            editMode={'row'}
          />
        </>
      )}
    </Box>
  );
};

export default EnhancedTable;
