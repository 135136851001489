import React from 'react';
import { 
    GET_ALL_CARS,
    REGISTER_CARS_UPDATE,
    GET_ALL_USERS,
    GET_REPORT_CAR_BY_ID,
    INITIAL_CAR_VALUE,
    INITIAL_CAR_VALUE_ERROR,
    INITIAL_REGISTER_CAR_VALUE_ERROR,
    INITIAL_REGISTER_CAR_VALUE 
} from '../../common/constants';

import { CheckCarRequiredFields, CheckRegisterCarRequiredFields } from '../../common/utils';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import RegisterCarForm from './RegisterCarForm';


const RegisterCarUpdate = ({regisaterCar, setToast, refetch}) => {
    const [open, setOpen] = React.useState(!!regisaterCar);
    const axiosPrivate = useAxiosPrivate();
    const [errors, serErrors] = React.useState(INITIAL_REGISTER_CAR_VALUE_ERROR);
    const [registerCarMapper, setRegisterCarsMapper] = React.useState(INITIAL_REGISTER_CAR_VALUE);
    const [users, setUsers] = React.useState([]);
    const [cars, setCars] = React.useState([]);


    const builderCarMapper = (registerCar) => {
        const handleTmpUserMapper = {
            id: registerCar.id,
            userId: registerCar.userId,
            date: registerCar.date,
            total_kilometers: registerCar.total_kilometers,
            carId: registerCar.carId,
            report: registerCar.report,
          };
          setRegisterCarsMapper(handleTmpUserMapper);


    }

    const getData = React.useCallback( async () => {
        const userResponses = await axiosPrivate.get(GET_ALL_USERS);
        const carsResponses = await axiosPrivate.get(GET_ALL_CARS);
        if(userResponses.status === 201) {
          setUsers(userResponses.data.data)
        }

        if(carsResponses.status === 201) {
            setCars(carsResponses.data.data)
        }
    });


    const getCar = async () => {
        const response = await axiosPrivate.get(GET_REPORT_CAR_BY_ID.replace(':id', regisaterCar.id));
        if(response.data) {
            builderCarMapper(response.data);
            setOpen(true)
        } else {
            setOpen(false)
        }
    }


    const updateRegisterCar = async () => {
        const checkErrors = CheckRegisterCarRequiredFields(registerCarMapper);
        if(checkErrors.errorCounter === 0){
            await axiosPrivate.put(
                REGISTER_CARS_UPDATE,
                JSON.stringify({ registerCarMapper }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            ).then(() => {
                refetch();
                setRegisterCarsMapper(INITIAL_CAR_VALUE)
                setToast({
                    open: true,
                    message: "Registro Vehículo modificado con éxito",
                    severity: "success",
                })   
            })
            .catch((error) => {
                console.log('errror', error);
                setToast({
                    open: true,
                    message: "Error modificado el Registro Vehículo, intentelo de nuevo.",
                    severity: "error",
                });        
            });
            setOpen(false);
            serErrors(INITIAL_CAR_VALUE_ERROR); 
            
        }else{
            console.log('errors', errors);
            setToast({
                open: true,
                message: "Revisa los datos del formulario.",
                severity: "error",
            }); 
            serErrors(checkErrors.userErrors);
        }
    }

    React.useEffect(() => {
        if(regisaterCar.id && !open){
            getCar();
            getData();
        }
    },[regisaterCar]);

    return (
        <Panel
        title={"Editar Registro Vehículo"} 
        open={open}
        bodyStyle={{ overflow: "hidden" }}
        bodyText={<RegisterCarForm registerCarData={registerCarMapper} cars={cars} users={users} setRegisterCarData={setRegisterCarsMapper} errors={errors}/>}
        buttonAccept={"aceptar"}
        acceptAction={() => updateRegisterCar()}
        requestClose={() => {serErrors(INITIAL_CAR_VALUE_ERROR); setRegisterCarsMapper(INITIAL_CAR_VALUE); setOpen(false); } }
      />
    )
};

export default RegisterCarUpdate;