import React from 'react';
import { GET_ESTIMATED_SIGNING_BY_ID, INITIAL_SIGNING_VALUE, INITIAL_SIGNING_VALUE_ERROR } from '../../common/constants';
import { checkSigningForm } from '../../common/utils';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import SigningForm from './estimatedSigningForm';

const SigningUpdate = ({signing, setToast, refetch, setSigning, otps}) => {
    
    const [open, setOpen] = React.useState(!!signing);
    const axiosPrivate = useAxiosPrivate();
    const [errors, setErrors] = React.useState(INITIAL_SIGNING_VALUE_ERROR);
    const [signingMapper, setSigningMapper] = React.useState(INITIAL_SIGNING_VALUE);

    const builderUserMapper = signing => {
        const handleTmpUserMapper = {
            id: signing.id,
            date: signing.date,
            user: signing.user,
            hours: signing.hours,
            otpId: signing.otpId,
            description: signing.description,
            concepts: {
              ACS_U_PL_DESPLAZAM: !!signing.fakeSigningOnConcepts.find(data => data.concept.description === 'ACS_U_PL_DESPLAZAM' ),
              ACS_U_MDIA_DIETA: !!signing.fakeSigningOnConcepts.find(data => data.concept.description === 'ACS_U_MDIA_DIETA' ),
              ACS_U_PLUS_AY_MANU: !!signing.fakeSigningOnConcepts.find(data => data.concept.description === 'ACS_U_PLUS_AY_MANU' ),
              VACACIONES: !!signing.fakeSigningOnConcepts.find(data => data.concept.description === 'VACACIONES' ),
              ACS_HORAS_ENF_PT: !!signing.fakeSigningOnConcepts.find(data => data.concept.description === 'ACS_HORAS_ENF_PT' ),

            }
          };
        setSigningMapper(handleTmpUserMapper);

    }


    const getSigning = async () => {
        const response = await axiosPrivate.post(GET_ESTIMATED_SIGNING_BY_ID, JSON.stringify( {id: signing.id } ));
        if(response.status === 201) {
            builderUserMapper(response.data.data)
            setOpen(true);
        }
    }

    const updateSigning = async () => {
        const checkErrors = checkSigningForm(signingMapper);
        if(checkErrors.errorCounter === 0){
            const {id, userId, otpId, date, hours, concepts, description } = signingMapper;
            await axiosPrivate.put(
                '/updateEstimatedSigning',
                JSON.stringify({ id, userId, otpId, date, hours, concepts, description }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
                ).then(() => {
                    refetch();
                setToast({
                    open: true,
                    message: "Imputación estimada modificada con éxito",
                    severity: "success",
                })   
            })
            .catch(error => {
                console.log('error', error);
                setToast({
                    open: true, 
                    message: "Error modificando imputación estimada",
                    severity: "error",
                });        
            });
            setOpen(false);
            setSigning(false);
            setErrors(INITIAL_SIGNING_VALUE_ERROR)
        }else{
            setOpen(false);
            setSigning(false);
            setErrors(checkErrors.signingErrors)
        }
    }

    React.useEffect(() => {
        if(signing.id && !open){
            getSigning();
        }
    },[signing]);

    return (
        <Panel
        title={"EDITAR IMPUTACIÓN"}
        open={open}
        bodyStyle={{ overflow: "hidden" }}
        bodyText={<SigningForm signingData={signingMapper} setSigningData={setSigningMapper} errors={errors} otps={otps} />}
        buttonAccept={"aceptar"}
        acceptAction={() => updateSigning()}
        requestClose={() => { setErrors(INITIAL_SIGNING_VALUE_ERROR); setSigningMapper(INITIAL_SIGNING_VALUE); setOpen(false); setSigning(false);} }
        />
    )
}

export default SigningUpdate;
