export const ROLES = {
  WORKER: 1,
  ADMIN: 2,
  DEVADMIN: 3,
};
export const ROLES_BUILDER = [
  'Worker',
  'Admin',
  'DevAdmin'
]

export const LOGIN_URL = "/login";
export const LOGOUT_URL = "/logout";
export const PROFILE_URL = "/getUsers";
export const GETUSERS_URL = "/getUser";
export const GET_USER_BY_ID = '/getUser/:id'
export const UPDATE_USER = "/updateUser"
export const CREATE_USER = "/createUser"
export const TOGLE_ACTIVE_USER = "/togleActiveUser"
export const FORGOT_PASSWD = "/forgotPasswd"
export const CHANGE_PASSWD = '/changePasswd';
export const LOCALSTORAGE_AMBIENTMODE = "ambientMode";

//CARS ROUTES
export const CARS_CREATE = '/cars'
export const GET_PAGINATED_CARS = '/paginatedCars'
export const GET_CAR_BY_ID = '/cars/id/:id';
export const GET_CAR_BY_USER_ID = '/cars/user/:userId';
export const GET_ALL_CAR_BY_USER_ID = '/cars/all/users/:userId';
export const CARS_UPDATE = '/cars';
export const GET_ALL_CARS = '/getAllCars';
export const TOGLE_ACTIVE_CAR = "/togleActiveCar"



// TRACKER ROUTES 
export const REPORT_PAGINATED_CARS = '/paginatedTrackerCars';
export const GET_REPORT_CAR_BY_ID = '/trackerCars/:id'
export const REGISTER_CARS_UPDATE = '/trackerCars'
export const REGISTER_CARS_CREATE = '/trackerCars'
export const GENERATE_REPORT_CAR_EXCEL = '/download/trackerCars/excel';

// DASHBOARD ROUTES
export const DASHBOARD_FIRST_ROW = "/dashboard/firstRow"
export const VALDIATE_CHANGE_PASSWD_TOKEN = '/validateChangePasswdToken'
export const GET_SIGNING_LINE = '/getSigningLine'
export const GET_OTP_LINE = '/getOtpLine'

//DASHBOARD ROUTES
export const DOWNLOAD_REPORT_PEOPLENET = '/downloadReport';

//ESTIMACION IMPUTACIONES
export const GET_ESTIMATED_SIGNINGS = '/estimatedSigning'
export const GET_ESTIMATED_SIGNING_BY_ID = '/getEstimatedSigningById';
export const GET_ESTIMATED_SIGNINGS_URL = '/filteredEstimatedSigning';
export const UPDATE_ESTIMATED_SIGNING = '/updateEstimatedSigning';

// OTPS ROUTES
export const OTPS_URL = "/get_all_otps";
export const GET_OTP_BY_ID = "/getOtp/:id"
export const UPDATE_OTP = "/updateOtp"
export const CREATE_OTP = "/createOtp"
export const TOOGLE_ACTIVE_OTP = "/togleActiveOtp"

export const GETISIGNINGS_URL = '/filteredSigning'
export const GET_ALL_USERS = '/getallusers'
export const GET_ALL_OTPS = '/getallotps';

export const DELETE_SIGNING = '/deleteSigning';

export const DELETE_ESTIMATION_SIGNING = '/deleteEstimationSigning';

export const GET_SIGNING_BY_ID = '/getSigningById';

export const UPDATE_SIGNING = '/updateSigning';

export const RELOAD_OTP_LINE = '/reloadOtpLine';

export const RELOAD_SIGNING_LINE = '/reloadSigningLine'

export const GET_LAST_SIGNING = '/getLastSignings';

export const CREATE_ESTIMATION_SIGNING = '/createEstimationSigning';

export const GENERATE_FILE = '/genertatePDF'

export const DOWNLOAD_URL = '/getDownloads';

export const REGENERATE_PDF = '/regeneratePDF';

export const  GET_USER_LINK_PASWD = '/getUserLink'

export const ACTIONS_ACTIVE_DESACTIVE = [
  'Activar',
  'Desactivar'
];

export const INITIAL_USER_VALUE = {
  id: '',
  name: '',
  surnames: '',
  workState: '',
  contractNumber: '',
  email: '',
  concepts: {
    ACS_U_PL_DESPLAZAM: false,
    ACS_U_MDIA_DIETA: false,
    ACS_U_PLUS_AY_MANU: false,
  }
};

export const INITIAL_CAR_VALUE = {
  type: '',
  license_plate: '',
  brand: '',
  model: '',
  initial_kilometers: '',
  company: '',
  userId: -1
};

export const INITIAL_CAR_VALUE_ERROR = {
  type: '',
  license_plate: '',
  brand: '',
  model: '',
  initial_kilometers: '',
  company: '',
};


export const INITIAL_REGISTER_CAR_VALUE_ERROR = {
  date: '',
  total_kilometers: '',
  userId: '',
  carId: '',
  report: '',
};



export const INITIAL_REGISTER_CAR_VALUE = {
  date: '',
  total_kilometers: '',
  userId: '',
  carId: '',
  report: '',
};

export const INITIAL_OTP_VALUE = {
  id: '',
  name: '',
  description: '',
  hours: 0,
  estimationHours: 0 
};

export const INITIAL_OTP_VALUE_ERROR = {
  id: '',
  name: '',
  description: '',
  hours: '',
  estimationHours: '' 
};

export const INITIAL_USER_VALUE_ERROR = {
  id: '',
  name: '',
  surnames: '',
  workState: '',
  contractNumber: '',
  email: '',
};

export const INITIAL_SIGNING_VALUE_ERROR = {
  hours: '',
  concepts: {
    ACS_U_PL_DESPLAZAM: false,
    ACS_U_MDIA_DIETA: false,
    ACS_U_PLUS_AY_MANU: false,
    VACACIONES: false,
    ACS_HORAS_ENF_PT: false
  }
}
export const INITIAL_SIGNING_VALUE = {
  hours: '',
  concepts: {
    ACS_U_PL_DESPLAZAM: false,
    ACS_U_MDIA_DIETA: false,
    ACS_U_PLUS_AY_MANU: false,
    VACACIONES: false,
    ACS_HORAS_ENF_PT: false
  }
}

export const CONCEPTS_TYPES = {
  ACS_U_PL_DESPLAZAM: {
      description: 'ACS_U_PL_DESPLAZAM',
      value: 'Plus desplazamiento'
  },
  ACS_U_MDIA_DIETA: {
      description: 'ACS_U_MDIA_DIETA',
      value: 'Plus media dieta'
  },
  ACS_U_PLUS_AY_MANU: {
      description: 'ACS_U_PLUS_AY_MANU',
      value: 'Plus ayuda manutención'
  }, 
  VACACIONES: {
      description: 'Vacaciones',
      value: 'Vacaciones'
  },
  ACS_HORAS_ENF_PT: {
      description: 'ACS_HORAS_ENF_PT',
      value: 'Baja Por Enfermedad'
  }
}


export const DOWNLOADS_STATUS = {
  ERROR: -1,
  PENDING: 0,
  DOWNLOADED: 1,
}


export const CAR_TYPES = [
  {
    value: 'TURISMO_P',
    label: 'Turismo Pequeño'
  },
  {
    value: 'TURISMO_M',
    label: 'Turismo Mediano'
  },
  {
    value: 'TURISMO_G',
    label: 'Turismo Grande'
  },
  {
    value: 'FURGONETA_P',
    label: 'Furgoneta Pequeño'
  },
  {
    value: 'FURGONETA_M',
    label: 'Furgoneta Mediano'
  },
  {
    value: 'FURGONETA_G',
    label: 'Furgoneta Grande'
  },
]

export const CAR_TYPES_VALUES = {
  TURISMO_P: 'Turismo Pequeño',
  TURISMO_M: 'Turismo Mediano',
  TURISMO_G: 'Turismo Grande',
  FURGONETA_P: 'Furgoneta Pequeño',
  FURGONETA_M: 'Furgoneta Mediano',
  FURGONETA_G: 'Furgoneta Grande'
}



export const DOCUMENT_TYPES = {
  REPORT_PEOPLENET: "REPORT_PEOPLENET",
  REPORT_WORKERS: "REPORT_WORKERS",
  REPORT_WORKER_DAY: "REPORT_WORKER_DAY",
  REPORT_TRACKER_VEHICLE: "REPORT_TRACKER_VEHICLE",
    REPORT_CAR_DAY: "REPORT_CAR_DAY"
};

export const DOCUMENT_TYPES_BUILDER = [
  {
    title : "Reporte Peoplenet (EXCEL)",
    value : DOCUMENT_TYPES.REPORT_PEOPLENET,
    description: ""
  },
  {
    title: "Partes de Trabajo (PDF)",
    value : DOCUMENT_TYPES.REPORT_WORKER_DAY,
    description: ""
  },
  {
    title: "Reporte de Vehículos (EXCEL)",
    value : DOCUMENT_TYPES.REPORT_TRACKER_VEHICLE,
    description: ""
  }, 
  ,
  {
    title: "Reporte de Vehiculos Diario(PDF)",
    value : DOCUMENT_TYPES.REPORT_CAR_DAY,
    description: ""
  }, 
]