import React from 'react';
import { 
    GET_ALL_CARS,
    REGISTER_CARS_CREATE,
    GET_ALL_USERS,
    GET_CAR_BY_ID,
    GET_CAR_BY_USER_ID,
    GET_ALL_CAR_BY_USER_ID,
    INITIAL_CAR_VALUE,
    INITIAL_CAR_VALUE_ERROR,
    INITIAL_REGISTER_CAR_VALUE_ERROR,
    INITIAL_REGISTER_CAR_VALUE 
} from '../../common/constants';
import { CheckRegisterCarRequiredFields } from '../../common/utils';
import Panel from '../../components/shared/Panel';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import RegisterCarForm from './RegisterCarForm';


const RegisterCarCreate = ({open, setToast, refetch, setOpen, otps}) => {
    
    const axiosPrivate = useAxiosPrivate();
    const [errors, setErrors] = React.useState(INITIAL_REGISTER_CAR_VALUE_ERROR);
    const [registerCarMapper, setRegisterCarsMapper] = React.useState(INITIAL_REGISTER_CAR_VALUE);
    const [users, setUsers] = React.useState({});
    const [cars, setCars] = React.useState([]);

    // const builderCarMapper = (registerCar) => {
    //     const handleTmpUserMapper = {
    //         id: registerCar.id,
    //         userId: registerCar.userId,
    //         date: registerCar.date,
    //         total_kilometers: registerCar.total_kilometers,
    //         carId: registerCar.carId,
    //         report: registerCar.report,
    //       };
    //       setRegisterCarsMapper(handleTmpUserMapper);


    // }

    const getData = React.useCallback( async () => {
        const userResponses = await axiosPrivate.get(GET_ALL_USERS);
        if(userResponses.status === 201) {
          setUsers(userResponses.data.data)
        }
    }, []);

    // const getCarById = async () => {
    //     let response = {}

    //     response = await axiosPrivate.get(GET_CAR_BY_ID.replace(':id', registerCarMapper.carId));
    //     registerCarMapper.userId = response.data["userId"]
    //     registerCarMapper.total_kilometers = response.data["actual_kilometers"]
    //     setRegisterCarsMapper(registerCarMapper)
    // }

    const getCarByUser = async () => {
        let response = {}
        if ( registerCarMapper.userId) {

            response = await axiosPrivate.get(GET_ALL_CAR_BY_USER_ID.replace(':userId', registerCarMapper.userId));
            registerCarMapper.carId = response.data["id"]
            registerCarMapper.total_kilometers = response.data["actual_kilometers"]
            
            if (response) {
                setCars(response.data)   
                setRegisterCarsMapper(registerCarMapper)
            }
        }
    }
  


    const createKMSigning = async () => {
        const checkErrors = CheckRegisterCarRequiredFields(registerCarMapper);
        if(checkErrors.errorCounter === 0){
            await axiosPrivate.post(
                REGISTER_CARS_CREATE,
                JSON.stringify(registerCarMapper),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
                ).then((data) => {
                    if (data.error) {
                        setToast({
                            open: true,
                            message: data.data.message,
                            severity: "error",
                        })     
                    } else {

                        setToast({
                            open: true,
                            message: "Imputación de KM creada con éxito",
                            severity: "success",
                        })  
                        refetch();
 
                    };
                })
                .catch(() => {
                  setToast({
                    open: true,
                    message: "Error",
                    severity: "error",
                })        
                });
                setOpen(false);
                setErrors(INITIAL_REGISTER_CAR_VALUE_ERROR)
        }else{
            setToast({
                open: true,
                message: "Revisa los datos del formulario.",
                severity: "error",
            }); 
            setErrors(checkErrors.userErrors);
        }
    }


    React.useEffect(() => {
        if(open){
            // getCarById();
            getData();
        }
    },[open]);

    React.useEffect(() => {
        getCarByUser()
    },[registerCarMapper.userId]);


    return (
        <Panel
        title={"CREAR IMPUTACIÓN"}
        open={open}
        bodyStyle={{ overflow: "hidden" }}
        bodyText={<RegisterCarForm registerCarData={registerCarMapper} setRegisterCarData={setRegisterCarsMapper} errors={errors} cars={cars} activeAllFields={true} users={users} />}
        buttonAccept={"aceptar"}
        acceptAction={() => createKMSigning()}
        requestClose={() => { setErrors(INITIAL_REGISTER_CAR_VALUE_ERROR); setRegisterCarsMapper(INITIAL_REGISTER_CAR_VALUE); setOpen(false);} }
        />
    )
}

export default RegisterCarCreate;
