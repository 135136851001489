import React from 'react';
import { Select, MenuItem, FormControl, Box } from '@mui/material';
import Calendar from './calendar/Calendar';

const CustomSelectTime = ({ onDateRangeChange }) => {
  const [selectedOption, setSelectedOption] = React.useState('today');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);

    const now = new Date();
    let start = null;
    let end = null;

    switch (event.target.value) {
      case 'today':
        start = end = now;
        break;
      case 'thisWeek':
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6));
        break;
      case 'thisMonth':
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case 'custom':
        break;
      default:
        break;
    }

    if (event.target.value !== 'custom') {
      onDateRangeChange({
        startDate: start,
        endDate: end
      });
    }
  };

  const handleDateChange = (rangeDate) => {
    onDateRangeChange({
      startDate: rangeDate[0],
      endDate: rangeDate[1]
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', width: '100%' }}>
      Fecha
      <FormControl fullWidth sx={{ mt: 1, width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          <Select value={selectedOption} onChange={handleOptionChange}>
            <MenuItem value="today">Hoy</MenuItem>
            <MenuItem value="thisWeek">Esta semana</MenuItem>
            <MenuItem value="thisMonth">Este mes</MenuItem>
            <MenuItem value="custom">Personalizado</MenuItem>
          </Select>
        </div>
        <div>
          {selectedOption === 'custom' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '16px', marginTop: { xs: '16px', md: 0 } }}>
              <Calendar handleDateChange={handleDateChange} />
            </Box>
          )}
        </div>
      </div>
      </FormControl>

    </Box>
  );
};

export default CustomSelectTime;
