import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { DateRangePicker } from "react-dates";
import DatePresets from "./DatePresets";
import moment from "moment";
import 'moment/locale/es'; // Importar el locale español

export default function Calendar({ handleDateChange }) {
  moment.locale('es');
  const dateFormat = "DD-MM-yyy";
  const [startDate, setStartDate] = useState(moment());
  const [dateEnd, setEndDate] = useState(moment().add(1, 'days'));
  const [dateInput, setDateInput] = useState(null);


  const isOutsideRange = (day) => {
    if (startDate && dateInput === 'endDate') {
      return day.diff(startDate, 'days') > 31; // Permite hasta 30 días desde la fecha de inicio
    }
    return false; // No restringe ninguna fecha de inicio
  };

  React.useEffect(() => {
    handleDateChange([startDate, dateEnd]);
  }, [startDate, dateEnd]);

  return (
    <div>
      <DateRangePicker
        startDate={startDate}
        isOutsideRange={isOutsideRange}
        startDateId="s_id"
        endDate={dateEnd}
        endDateId="e_id"
        firstDayOfWeek={1}
        onDatesChange={({ startDate, endDate }) => {
          console.log('startDate', startDate);
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        focusedInput={dateInput}
        onFocusChange={(e) => setDateInput(e)}
        displayFormat={dateFormat}
        renderCalendarInfo={() => (
          <DatePresets
            startDate={startDate}
            endDate={dateEnd}
            displayFormat={dateFormat}
            handlePresets={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        )}
      />
      <div className="mt-3">
        <div>
          <strong>Fecha Inicio: </strong>
          {startDate && startDate.format("ll")}
        </div>
        <div>
          <strong>Fecha Fin: </strong>
          {dateEnd && dateEnd.format("ll")}
        </div>
      </div>
    </div>
  );
}