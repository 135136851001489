import React from "react";
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import LogedLayout from "../../components/shared/LogedLayout";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Header from "../../components/shared/Header";
import { CAR_TYPES_VALUES, GET_ALL_CARS, REPORT_PAGINATED_CARS, TOGLE_ACTIVE_USER } from "../../common/constants.js";
import LoadingSection from "../../components/shared/LoadingSection.js";
import EnhancedTable from "../../components/shared/EnhancesTable";
import { Dropdown, DropdownMenuItem } from "../../components/shared/Dropdown";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getDatFromColumn } from "../../common/utils";
import EnhancedToast from "../../components/shared/enhancesToast";
import EnhancedModal from "../../components/shared/EnhancedModal";
import moment from 'moment';
import RegisterCarUpdate from "./RegisterCarUpdate";
import Calendar from "../../components/shared/calendar/DateRange.js";
import RegisterCarCreate from "./RegisterCarCreate";

const RegisterCars = () => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = React.useState({ loading: true });
  const [editPanel, setEditPanel] = React.useState(false);
  const [createPanel, setCreatePanel] = React.useState(false);
  const [activeAction, setActiveAction] = React.useState(false);
  const [cars, setCars] = React.useState([]);

  const [toast, setToast] = React.useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [filters, setFilters] = React.useState({
    car: -1,
    rangeDate: {
      startDate: new Date(moment().endOf('day')),
      endDate: new Date(moment().startOf('day')),
    },
  });

  const createKmSigning = React.useCallback((async) => {
    setCreatePanel(true);
  });

  const USER_COLUMN = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5
    },
    {
      field: "car",
      headerName: "Vehículo",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params);
        return (
          thisRow.car.car.license_plate + ' ' + thisRow.car.car.brand + ' ' + thisRow.car.car.model)
      }
    },
    {
      field: "date",
      headerName: "Fecha",
      flex: 1,
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params);
        return (
          moment(thisRow.date.date).format('DD/MM/YYYY'))
      }
    },
    {
      field: "user",
      headerName: "Usuario",
      flex: 1.5,
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params);
        return (
          thisRow.user.user.name + ' ' + thisRow.user.user.surnames)
      }
    },
    {
      field: "report",
      headerName: "Reporte",
      flex: 1.5,
    },
    {
      field: "total_kilometers",
      headerName: "Kilometros registrados",
      flex: 1,
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params);
        return (
          parseFloat(thisRow.total_kilometers.total_kilometers, 10).toFixed(2) + ' KM'
        )
      }
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => {
        const thisRow = getDatFromColumn(params).id;
        const onClickEdit = e => {
          setEditPanel(thisRow)
          e.stopPropagation(); // don't select this row after clicking
        };
        return (
          <Dropdown
            trigger={
              <IconButton>
                <MoreVertIcon sx={{ fontSize: '1.25rem' }} />
              </IconButton>
            }
            menu={[
              <DropdownMenuItem onClick={onClickEdit}>Editar</DropdownMenuItem>,
            ]}
          />
        );
      },
    }
  ];

  const [options, setOptions] = React.useState({
    takeValue: 10,
    skipValue: 0,
    rowsPerPage: 10,
  });


  const getCars = React.useCallback(async () => {
    const response = await axiosPrivate.get(GET_ALL_CARS);
    if (response.status === 201) {
      setCars(response.data.data);
    }
  });

  const getTrackerCars = React.useCallback(async () => {
    const { takeValue, skipValue } = options
    const response = await axiosPrivate.post(REPORT_PAGINATED_CARS,
      JSON.stringify({ takeValue, skipValue, filters }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );
    if (response) {
      setData({ loading: false, data: response.data.data, totalRowCount: response.data.totalRowCount });
    }
  }, [axiosPrivate, options, filters])

  React.useEffect(() => {
    getTrackerCars();
  }, [getTrackerCars]);

  React.useEffect(() => {
    getCars();
  }, []);

  if (data.loading) {
    return <LoadingSection />;
  }
  return (
    <LogedLayout>
      <Box m="20px">
        <Header
          title="Registro de Vehículos"
          subtitle="Listado de Registro de Vehículos"
          secondButtonText={"Añadir Imputación de Vehículo"}
          secondButtonAction={() => createKmSigning()}
        />
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <FormControl
              fullWidth
              variant="standard"
              sx={{ m: 1, minWidth: 120 }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Vehiculos
              </InputLabel>
              <Select value={filters.car} onChange={event => { setFilters({ ...filters, car: event.target.value }) }}>
                <MenuItem value={-1}>Todos los vehiculos</MenuItem>
                {cars &&
                  cars.map((car) => (
                    <MenuItem
                      key={car.id}
                      value={car.id}
                    >{`${car.license_plate}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
                <Calendar onChange={event => { setFilters({ ...filters, rangeDate: event[0]}); }} />
            </Grid>
        </Grid>
        {data &&
          <EnhancedTable
            data={data.data}
            options={options}
            setOptions={setOptions}
            header={USER_COLUMN}
            checkboxSelection
            totalRow={data.totalRowCount}
            onRowClick={() => setEditPanel(true)}
          />
        }
      </Box>

      <RegisterCarCreate 
        open={createPanel}
        setOpen={setCreatePanel}
        setToast={setToast}
        refetch={getTrackerCars}
        otps={[]}
      />
      <RegisterCarUpdate regisaterCar={editPanel} setToast={setToast} refetch={getTrackerCars} />

      <EnhancedToast toast={toast} setToast={setToast} />


    </LogedLayout>
  );




};

export default RegisterCars;
