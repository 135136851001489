import React from "react";
import moment from "moment";
import Button from '@mui/material/Button';
import { tokens } from "../../../theme/theme";
import { useTheme } from "@emotion/react";

const DatePresets = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { handlePresets } = props;
  const today = moment();
  const presets = [
    {
        text: "Mañana",
        start: moment().add(1, "days"),
        end: moment().add(1, "days"),
    },
    {
      text: "1 semana",
      start: today,
      end: moment().add(1, "week"),
    },
    {
      text: "1 Mes",
      start: today,
      end: moment().add(1, "month"),
    },
  ];
  return (
    <div className="p-3">
      {presets.map(({ text, start, end }) => {
        return (
          <Button
            variant="outlined"
            sx={{
                borderColor: colors.blueAccent[700],
                color: colors.blueAccent[700],
                fontSize: "14px",
                fontWeight: "bold",
                marginTop: "2rem",
                padding: "10px 20px",
                margin: '1rem'
                }}
            onClick={() => handlePresets(start, end)}
          >
            {text}
          </Button>
        );
      })}
    </div>
  );
};

export default DatePresets;